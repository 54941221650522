import { Constants } from '@/app/lib/constants';
import type { BuildCostSummary } from '@/app/lib/dtos/BuildCostSummary';
import type { ClientSocketEmit } from '@/app/lib/dtos/ClientSocketEmit';
import type { ToolCallRequest } from '@/app/lib/dtos/ToolCallRequest';
import type { ToolCallResponse } from '@/app/lib/dtos/ToolCallResponse';
import { Build } from '@/app/lib/entities/Build.entity';
import type { ChatMessage } from '@/app/lib/entities/ChatMessage.entity';
import type { Thread } from '@/app/lib/entities/Thread.entity';
import type { ThreadFile } from '@/app/lib/entities/ThreadFile.entity';
import type { User, UserType } from '@/app/lib/models/user';
import { Shared, type ToolType } from '@/app/lib/shared';
import { socket } from '@/app/socket';
import { SVGAvatar1 } from '@/components/svg/avatar1';
import { SVGAvatar2 } from '@/components/svg/avatar2';
import { SVGAvatar3 } from '@/components/svg/avatar3';
import { SVGAvatar4 } from '@/components/svg/avatar4';
import { SVGAvatar5 } from '@/components/svg/avatar5';
import { SVGAvatar6 } from '@/components/svg/avatar6';
import { SVGProfile } from '@/components/svg/profile';
import { Tool } from '@/components/svg/tool';
import { marked } from 'marked';
import type React from 'react';

export interface ToolDisplayProps {
	from: User;
	message: string;
	toolName: ToolType;
	profileImage: () => React.JSX.Element;
}

// eslint-disable-next-line
export class Client {
	static CreateMessage(content: string, thread: Thread): Partial<ChatMessage> {
		let toUser = determineRecipient(content);
		if (thread.threadType == 'user') {
			toUser = (thread.name ?? 'projectManager') as UserType;
		}
		return {
			datePosted: new Date(),
			content,
			fromUser: 'user',
			contentType: 'text',
			toUser,
			threadId: thread.id,
		};
	}

	static Emit(message: ClientSocketEmit) {
		socket.emit(message.type, message);
	}

	static GetUserId(): string | undefined {
		if (globalThis.localStorage) {
			let userId = globalThis.localStorage.getItem('userId') ?? undefined;
			if (!userId) {
				userId = Shared.RandomString();
				globalThis.localStorage.setItem('userId', userId);
			}
			return userId;
		}
		return undefined;
	}

	static ToLegibleDate(maybeDate: Date | undefined): string {
		let legibleDate = '';

		if (maybeDate) {
			const date = new Date(maybeDate);

			if (!isNaN(Number(date))) {
				const today = new Date();
				const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());

				if (date < todayStart) {
					legibleDate = date.toLocaleString();
				} else {
					legibleDate = date.toLocaleTimeString();
				}
			}
		}

		return legibleDate;
	}

	static DetermineBuildState(build: Build): string {
		if (build.cancellationState == 'cancelled') {
			return 'Paused';
		} else if (build.statusMajor == 'awaiting') {
			return 'Waiting to start';
		} else if (build.statusMajor == 'working') {
			return 'Working';
		}
		return 'Completed';
	}

	static DetermineBuildType(build: Build): string {
		if (build.buildType == 'preview') {
			return 'Preview';
		} else if (build.buildType == 'amend-full-production' || build.buildType == 'amend-prototype') {
			return 'Applying requested changes';
		} else if (build.buildType == 'prototype') {
			return 'Prototype';
		}
		return 'Full Production Build';
	}

	static DetermineWorkingBuildState(build: Build): string {
		let response = '';
		if (build.statusMajor == 'working') {
			response += `The build process has ${build.statusPatch == '1-started' ? 'started' : 'finished'} `;
			if (build.statusMinor == '1-run-check') {
				response += 'checking the project suitability';
			} else if (build.statusMinor == '2-run-brief') {
				response += 'gathering project requirements';
			} else if (build.statusMinor == '3-run-architecture') {
				response += 'designing the application architecture';
			} else if (build.statusMinor == 'x-remove-conversation') {
				response += 'removing old files';
			} else if (build.statusMinor == '4-run-generation') {
				response += 'generating the application code';
			} else if (build.statusMinor == '5-add-tests') {
				response += 'generating the testing code';
			} else if (build.statusMinor == '6-run-install') {
				response += 'installing the dependencies';
			} else if (build.statusMinor == '7-run-linting') {
				response += 'checking the code files for formatting errors';
			} else if (build.statusMinor == '8-run-tests') {
				response += 'running tests';
			} else if (build.statusMinor == '9-run-build') {
				response += 'building the application';
			} else if (build.statusMinor == '10-run-host') {
				response += 'hosting the application';
			} else if (build.statusMinor == '11-get-port-number') {
				response += 'acquiring the hosting details';
			} else if (build.statusMinor == 'x-clear-file-system') {
				response += 'preparing file-system';
			} else if (build.statusMinor == '4-run-generation-with-amendments') {
				response += 'amending project structure';
			} else if (build.statusMinor == 'x-determine-complexity') {
				response += 'determining project complexity';
			} else if (build.statusMinor == '12-1-check-web-application-connectivity') {
				response += 'checking project connectivity';
			} else if (build.statusMinor == '12-2-check-web-application-routes') {
				response += 'checking project routes';
			}
		}
		return response;
	}

	static GetProfileImage = (userType: UserType | 'tool') => {
		let profileImage = SVGProfile;
		if (userType == 'projectManager') {
			profileImage = SVGAvatar1;
		} else if (userType == 'designer') {
			profileImage = SVGAvatar2;
		} else if (userType == 'architect') {
			profileImage = SVGAvatar3;
		} else if (userType == 'devops') {
			profileImage = SVGAvatar4;
		} else if (userType == 'engineer') {
			profileImage = SVGAvatar5;
		} else if (userType == 'tester') {
			profileImage = SVGAvatar6;
		} else if (userType == 'tool') {
			profileImage = Tool;
		}
		return profileImage;
	};

	static GetToolArguments(request: ToolCallRequest): Record<string, string> {
		const result: Record<string, string> = {};

		if (
			request.toolName == 'ask-architect' ||
			request.toolName == 'ask-designer' ||
			request.toolName == 'ask-devops' ||
			request.toolName == 'ask-engineer' ||
			request.toolName == 'ask-project-manager' ||
			request.toolName == 'ask-tester' ||
			request.toolName == 'create-specific-design' ||
			request.toolName == 'edit-design' ||
			request.toolName == 'ask-user-in-build'
		) {
			result.Question = request.arguments.question;
		} else if (request.toolName == 'execute-command-line') {
			result.Command = request.arguments.command;
		} else if (request.toolName == 'overwrite-file' || request.toolName == 'create-new-file') {
			result.Path = request.arguments.path;
		} else if (request.toolName == 'google-search') {
			result.Term = request.arguments.term;
		} else if (
			request.toolName == 'get-web-page' ||
			request.toolName == 'get-web-page-with-detail' ||
			request.toolName == 'web-page-interact-with-detail'
		) {
			result.Url = request.arguments.url;
		}
		return result;
	}

	static GetMessageBody(message: ChatMessage, file: ThreadFile | undefined): React.JSX.Element | undefined {
		const { context, fromUser, content, contentType } = message;
		let text: string | undefined = undefined;
		let markdown: string | undefined = undefined;
		let deleteContent = true;

		if (context) {
			if (context == 'request-architecture') {
				if (fromUser == 'user') {
					text = 'Generating Project Architecture';
				} else {
					text = 'Project Architecture created';
				}
			} else if (context == 'request-build-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to build the project';
				} else {
					deleteContent = false;
					text = 'Command to build the project';
					markdown = content as string;
				}
			} else if (context == 'request-fix-plan') {
				if (fromUser == 'user') {
					deleteContent = false;
					text = 'Obtaining the plan to fix the problem';
					markdown = Shared.ExtractMarkdown(String(content));
				} else {
					text = 'Obtained the plan to fix the problem';
				}
			} else if (context == 'request-fix-plan-step-execute') {
				if (fromUser == 'user') {
					text = 'Execute step in plan to fix problem';
				} else {
					text = 'Executed step in plan';
				}
			} else if (context == 'request-fix-plan-steps') {
				if (fromUser == 'user') {
					text = 'Ask for steps in the plan to fix problem';
				} else {
					text = 'Identified the steps in the plan';
				}
			} else if (context == 'request-hosting-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to start the project';
				} else {
					deleteContent = false;
					text = 'Command to start the project';
					markdown = content as string;
				}
			} else if (context == 'request-installation-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to install the project';
				} else {
					deleteContent = false;
					text = 'Command to install the project';
					markdown = content as string;
				}
			} else if (context == 'request-linting-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to lint the project';
				} else {
					deleteContent = false;
					text = 'Command to lint the project';
					markdown = content as string;
				}
			} else if (context == 'request-order-of-implementation') {
				if (fromUser == 'user') {
					text = 'Organizing project file structure';
				} else {
					text = 'Project file structure organized';
				}
			} else if (context == 'request-project-brief') {
				if (fromUser == 'user') {
					text = 'Gathering the project detail';
				} else {
					text = 'Gathered the project detail';
				}
			} else if (context == 'request-project-validity') {
				if (fromUser == 'user') {
					text = 'Checking if the project is ready to be built...';
				} else {
					deleteContent = false;
					text = `The project is ${
						String(content ?? '')
							.toLowerCase()
							.trim() == 'yes'
							? ''
							: 'not'
					} ready to be built`;
				}
			} else if (context == 'request-proposed-file-structure') {
				if (fromUser == 'user') {
					text = 'Generating project file structure';
				} else {
					text = 'Generated project file structure';
				}
			} else if (context == 'request-summarization') {
				if (fromUser == 'user') {
					text = 'Requested Summarization';
				} else {
					text = 'Generated Summarization';
				}
			} else if (context == 'request-testing-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to test the project';
				} else {
					deleteContent = false;
					text = 'Command to test the project';
					markdown = content as string;
				}
			} else if (context == 'write-code') {
				if (fromUser == 'user') {
					deleteContent = false;
					text = `Generating code for ${new RegExp('Create the code for the file "([^"].*?)"').exec(String(content))?.[1] ?? ''}`;
				} else {
					text = 'Code generated';
				}
			} else if (context == 'write-test-code') {
				if (fromUser == 'user') {
					deleteContent = false;
					text = `Generating test code for ${new RegExp('create the code for the file "([^"].*?)"').exec(String(content))?.[1] ?? ''}`;
				} else {
					text = 'Test code written';
				}
			} else if (context == 'request-applicable-files') {
				text = 'Querying for applicable files';
			} else if (context == 'directory-listing') {
				text = 'Generating directory-listing';
			} else if (context == 'request-repl-fix-score') {
				text = 'Request confidence in fixes applied';
			} else if (context == 'request-repl-fix-command-summary') {
				text = 'Summarizing the fixes applied';
			} else if (context == 'request-repl-fix-summary') {
				text = 'Summarizing the fixes applied';
			} else if (context == 'request-shell-execution-success') {
				text = 'Determining command success';
			} else if (context == 'summarize-conversation') {
				text = 'Summarizing conversation';
			} else if (context == 'find-port') {
				text = 'Determining port number';
			} else if (context == 'find-complexity') {
				text = 'Determining project complexity';
			} else if (context == 'request-error-summary') {
				text = 'Summarizing the error';
			} else if (context == 'ask-user-in-build') {
				text = 'Asking user a question';
			} else if (context == 'check-connectivity') {
				text = 'Checking connectivity to the hosted application';
			} else if (context == 'check-routes') {
				text = 'Checking routing and navigation';
			} else if (context == 'check-route-individual') {
				text = 'Checking each route';
			}
		} else if (contentType == 'text') {
			deleteContent = false;
			markdown = String(content);
		} else if (contentType == 'image') {
			deleteContent = false;
			if (file) {
				return <img className='max-w-lg' src={`data:image/png;base64,${file.content}`} alt={file.path} />;
			} else {
				text = 'File has been deleted';
			}
		}

		if (deleteContent) {
			// @ts-expect-error remove to reduce memory
			delete message.content;
		}

		if (text) {
			return (
				<div className='text-sm whitespace-pre-line'>
					{text}
					{!!markdown && Client.GetMarkdown(markdown)}
				</div>
			);
		} else if (markdown) {
			return Client.GetMarkdown(markdown);
		}

		return undefined;
	}

	static GetMarkdown(markdown: string): React.JSX.Element {
		return (
			<div
				className='text-sm whitespace-pre-line marked-down'
				dangerouslySetInnerHTML={{
					__html: marked
						.parse(markdown, { async: false })
						.replace(/(<\/.?>)\n</gi, '$1<')
						.replace(/>\n</gi, '><')
						.replace(/>\n</gi, '><')
						.trim(),
				}}
			/>
		);
	}

	static GetToolRequestMetaData(message: ChatMessage): ToolDisplayProps | undefined {
		if (message?.contentType == 'tool-request') {
			const request = message.content as ToolCallRequest;

			const args = Client.GetToolArguments(request);
			let argsExpanded = '';
			for (const key in args) {
				argsExpanded += `${key}: \`${args[key]}\``;
			}

			return {
				from: Shared.Users[message.fromUser],
				message: argsExpanded,
				profileImage: Client.GetProfileImage(message.fromUser),
				toolName: request.toolName,
			};
		}
		return undefined;
	}

	static GetToolResultMetaData(message: ChatMessage): ToolDisplayProps | undefined {
		if (message?.contentType == 'tool-result') {
			const response = message.content as ToolCallResponse;
			const result: ToolDisplayProps = {
				from: { id: 'tool' as UserType, displayName: 'Tool' },
				message: response.response,
				profileImage: Client.GetProfileImage('tool'),
				toolName: response.toolName,
			};

			result.profileImage = Client.GetProfileImage(result.from.id);

			return result;
		}
		return undefined;
	}

	static DeterminePricing(buildCostSummary: BuildCostSummary) {
		const tokensIn = buildCostSummary.totalTokensInput;
		const tokensOut = buildCostSummary.totalTokensOutput;
		const tokensTotal = tokensIn + tokensOut;
		const dollarsIn = (tokensIn / Constants.ONE_MILLION) * Constants.DOLLARS_PER_MILLION_TOKENS_IN;
		const dollarsOut = (tokensOut / Constants.ONE_MILLION) * Constants.DOLLARS_PER_MILLION_TOKENS_OUT;

		const cachedTokensIn = buildCostSummary.totalTokensCachedInput;
		const cachedTokensOut = buildCostSummary.totalTokensCachedOutput;
		const cachedTokensTotal = cachedTokensIn + cachedTokensOut;
		const cachedDollarsIn = (cachedTokensIn / Constants.ONE_MILLION) * Constants.DOLLARS_PER_CACHED_MILLION_TOKENS_IN;
		const cachedDollarsOut =
			(cachedTokensOut / Constants.ONE_MILLION) * Constants.DOLLARS_PER_CACHED_MILLION_TOKENS_OUT;

		return {
			tokens: tokensTotal + cachedTokensTotal,
			total: (cachedDollarsIn + cachedDollarsOut + dollarsIn + dollarsOut).toFixed(2),
		};
	}
}

const determineRecipient = (message: string) => {
	let recipientUserId: UserType | undefined = undefined;
	const tagRegex = /@[a-zA-Z]/gi;

	if (message && tagRegex.test(message)) {
		for (const user in Shared.Users) {
			if (message.includes(`@${user}`)) {
				recipientUserId = user as UserType;
				break;
			}
		}
	}

	return recipientUserId ?? 'projectManager';
};
